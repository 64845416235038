import React, { forwardRef, LegacyRef } from "react"
import classNames from "classnames"
import styled from '@emotion/styled'
import { FixedBottom } from "./FixedBottom"

export interface Props {
  className?: string;
  children?: React.ReactNode;
}


const AsideFooter = styled.div`
    border-top: 1px solid rgba(173, 173, 173, 0.2);
    width: 100%;
    a {
        box-shadow: none;
        color: currentColor;
    }
`;


const SidebarFooter: React.ForwardRefRenderFunction<unknown, Props> = (
  { children, className, ...rest },
  ref
) => {
  const sidebarFooterRef: LegacyRef<HTMLDivElement> =
    (ref as any) || React.createRef<HTMLDivElement>()
  return (
    <FixedBottom offset={20}>
    <AsideFooter {...rest} ref={sidebarFooterRef} className={classNames("aside-footer", className)}>
      {children}
    </AsideFooter>
    </FixedBottom>
  )
}

export default forwardRef<unknown, Props>(SidebarFooter)

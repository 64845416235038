// @ts-ignore
import React, { forwardRef, LegacyRef } from "react"
import { motion } from "framer-motion"


import classNames from "classnames"

export interface Props {
  children?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  active?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  firstchild?: boolean;
  popperarrow?: boolean;
}

const variants = {
  open: {
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    opacity: 1,
    transition: {
      y: { stiffness: 1000 }
    }
  }
}

const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"]


const MenuItem: React.ForwardRefRenderFunction<unknown, Props> = (
  { children, className, icon, active, prefix, suffix, firstchild, popperarrow, ...rest },
  ref
) => {
  const menuItemRef: LegacyRef<HTMLLIElement> = (ref as any) || React.createRef<HTMLLIElement>()

  return (
    <motion.li {...rest} ref={menuItemRef}
               variants={variants}
               whileHover={{ scale: 1.1, x: 10 }}
               whileTap={{ scale: 0.95 }}
               className={classNames("pro-menu-item", className, { active })}>
      <div className="pro-inner-item" tabIndex={0} role="button">
        {icon ? (
          <span className="pro-icon-wrapper">
            <span className="pro-icon">{icon}</span>
          </span>
        ) : null}

        {prefix ? <span className="prefix-wrapper">{prefix}</span> : null}
        <span className="pro-item-content">{children}</span>
        {suffix ? <span className="suffix-wrapper">{suffix}</span> : null}
      </div>
    </motion.li>
  )
}

export default forwardRef<unknown, Props>(MenuItem)

// custom typefaces
import "typeface-poppins"

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  console.log("Gatsby started to change location to", location.pathname)
  console.log("Gatsby started to change location from", prevLocation ? prevLocation.pathname : null)
}


export const onRouteUpdateDelayed = () => {
  console.log("Gatsby onRouteUpdateDelayed")
}

export const onRouteUpdate = () => {
  console.log("Gatsby onRouteUpdate")
}
import React from "react"
import styled from '@emotion/styled'
import { motion, AnimatePresence } from "framer-motion"
import { bgColor } from "../../utils/theme"

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1009;
  background: rgba(0, 0, 0, 0.3);
`
const ModalContainer = styled(bgColor)`
  width: 90%;
  max-height: 50%;
  z-index: 1010;
  overflow-y: auto;
  padding: 0 20px 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  max-width: calc(var(--max-width)*2/3 );
  ${props => {
    console.log(props, '??')
    if(props.conditionalClass == "citations") {
      return `
      max-width: 1080x; !important;
      left: var(--center-margin);
      `
    }
  }}
`
const CloseButton = styled.svg`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 18px;
  top: 18px;
  cursor: pointer;
`

const modalVariant = {
  initial: { opacity: 0 },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 }
}
const containerVariant = {
  initial: { top: "-50%", transition: { type: "spring" } },
  isOpen: { top: "50%" },
  exit: { top: "-50%" }
}
const Modal = ({ handleClose, children, isOpen, modalClass }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Overlay
          initial={"initial"}
          animate={"isOpen"}
          exit={"exit"}
          variants={modalVariant}
          onClick={handleClose}
        >
          <ModalContainer conditionalClass={modalClass} variants={containerVariant} as={motion.div} onClick={(e) => {
            //stop clicks getting to the overlay
            e.stopPropagation()
          }}>
            <CloseButton
              onClick={handleClose}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20.39 20.39"
            >
              <title>close</title>
              <line
                x1="19.39"
                y1="19.39"
                x2="1"
                y2="1"
                fill="none"
                stroke="var(--textLink)"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
              <line
                x1="1"
                y1="19.39"
                x2="19.39"
                y2="1"
                fill="none"
                stroke="var(--textLink)"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
            </CloseButton>
            {children}
          </ModalContainer>
        </Overlay>
      )}
    </AnimatePresence>
  )
}

export default Modal

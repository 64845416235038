// @ts-ignore
import React, { createContext, forwardRef } from "react"
import classNames from "classnames"
import { motion } from "framer-motion"
import { useDimensions } from "../../utils/dimensions"
import { BACKGROUND_TRANSITION_TIME, lightBgColor } from "../../utils/theme"
import styled from '@emotion/styled'

export interface Props {
  collapsed?: boolean;
  rtl?: boolean;
  toggled?: boolean;
  width?: string | number;
  image?: string;
  className?: string;
  children?: React.ReactNode;
  breakPoint?: "xl" | "lg" | "md" | "sm" | "xs";
  onToggle?: (value: boolean) => void;
}

export interface SidebarContextProps {
  collapsed: boolean;
  rtl: boolean;
  toggled: boolean;
}

export const SidebarContext = createContext<SidebarContextProps>({
  collapsed: false,
  rtl: false,
  toggled: false
})

function useHasMounted() {
  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => {
    setHasMounted(true)
  }, [])
  return hasMounted
}


const sidebar = {

  open: (height = 1000) => {
    console.log(height, "twis is triggered")
    return {
      clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
      transition: {
        delay: 0.25,
        type: "spring",
        stiffness: 20,
        restDelta: 2
      }
    }
  },
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0,
      duration: 0.25,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
}


const moveout = {

  open: {
    x: "0",
    transition: {
      delay: 0,
      duration: 0.25,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  },
  closed: {
    x: "-380px",
    transition: {
      delay: 0.25,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
}


const AsideLayout = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 101;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
`;


const AsideInner = styled.div`
  transition: background-color ${props =>props.theme.name === 'dark' ? '0s' : BACKGROUND_TRANSITION_TIME};
  background: var(--lightBg);
  height: 100%;
  position: relative;
  z-index: 101;

@screen xl {
  clip-path: none !important;
}

`;




const ProSidebar: React.ForwardRefRenderFunction<unknown, Props> = (
  { children, className, width, collapsed, rtl, toggled, image, breakPoint, onToggle, ...rest },
  ref
) => {


  const sidebarRef: React.RefObject<HTMLDivElement> =
    (ref as any) || React.createRef<HTMLDivElement>()



  let { height } = useDimensions(sidebarRef)

  // const ro = new ResizeObserver(() => {
  //   if (popperInstance) {
  //     popperInstance.update();
  //   }
  // });
  // TODO Fix initilizer, maybe deligate after load
  const initial_state = "open"
  return (
    <motion.div
      {...rest}
      ref={sidebarRef}
      className={classNames("aside", className, breakPoint, { collapsed, rtl })}
      style={{ width }}
      variants={moveout}
      custom={height}
      initial={false}
    >

      <AsideInner  initial={initial_state} variants={sidebar} custom={height} animate={toggled ? 'open' : 'closed'} as={motion.div}>
        {image ? <img src={image} alt="sidebar background" className="sidebar-bg" /> : null}
        <AsideLayout className="aside-layout">{children}</AsideLayout>
      </AsideInner>

    </motion.div>
  )
}

export default forwardRef<unknown, Props>(ProSidebar)

import React, { useEffect }  from "react"
import styled from '@emotion/styled'
import { motion, useAnimation, useViewportScroll } from "framer-motion"


const LogoMain = styled.svg`
    position: absolute;
    z-index: 100;
    transform-origin: top center;
    transform: translateX(calc(-340px / 4)) translateY(20px);
    top: 0;
    width: calc(300px / 2);
        
    .cls-4 {
        fill: #2f373a;
    }
    
    .cls-5 {
        fill: #323c3f;
    }
    
    .cls-8 {
        fill: #3f4b52;
    }
    
    .cls-14 {
        fill: #303a3d;
    }
    
    .cls-16 {
        fill: #ed3244;
    }
    
    
    .cls-19 {
        stroke: #cf2e3d;
    }
    
    .cls-19, .cls-21 {
        stroke-miterlimit: 10;
        stroke-width: 2px;
        fill: url(#linear-gradient-2);
    }
    
    .cls-20 {
        fill: url(#radial-gradient-3);
    }
    
    .cls-21 {
        stroke: #d82d3b;
    }
`;

const LogoCirclePattern = styled(motion.g)`
    //transform-box: fill-box;
    transform-origin: center;
`;

const LogoCirclePatternElement = ({animating, ...props }) => {
  const controls = useAnimation()
  // controls.start({ 'rotateZ': '360deg' })
  console.log('z again', controls)
  // if(animating){
  //   controls.start({ 'rotateZ': '360deg' })
  // } else {
  //   controls.start({ 'rotateZ': '0deg' })
  // }
  useEffect(() => {
    console.log('start again', controls)
    if(animating){
      // controls.start({
        // 'transform-origin': 'top center',
        // 'rotate': 360 })
    } else {
      //controls.start({ 'rotateZ': '0deg' })
    }
    // returned function will be called on component unmount
    return () => {
      controls.stop()
    }
  }, [animating])


  return           <LogoCirclePattern
    // initial={{ 'rotate': 0 }}
    animate={controls}
    transition={{ duration: 5, type: "tween", ease:'linear', repeat: Infinity,  from: 0  }}
  >
    <path style={{fill: '#445059'}}
          d="M249.68,30.45a181.61,181.61,0,0,1,66.91,4.9c5.73,1.55,21.85,6.59,30.3,11.12,1.9,1,7.41,3,7.38,4.75-.07,5-11.15,13.45-14.31,16.89-6.41,7-12.64,14.15-18.83,21.34C301.37,79.1,281.7,68.58,262,58.18c-4.78-2.52-22.89-8.73-22.68-13.92C239.43,40.71,247.8,33,249.68,30.45Z" />
    <path style={{fill: '#2e3537'}}
          d="M138.6,305.64q-4.71,3.58-9.52,7A176.7,176.7,0,0,1,95.5,233.22,106.38,106.38,0,0,1,93.91,218a32.48,32.48,0,0,1-.23-4.34c.76-5.39,3.22-10.23,4.15-15.71.83-4.93-.25-21,6-21.67,3.46,12.69,3.32,27.43,4.57,40.52.83,8.69-.69,24.94,7.43,30.68-7.68,4.27,11.35,38.22,15,42.6C134.2,294.08,138.81,300.08,138.6,305.64Z" />
    <path className="cls-4"
          d="M103.64,176.25c-5.89,1.65-4.56,13.15-4.71,17.91a41,41,0,0,1-5.32,19.93,179.58,179.58,0,0,1,6.43-56C104.72,158.56,103.83,172.94,103.64,176.25Z" />
    <path className="cls-5"
          d="M202.36,355.07c-6.73-22,26.06-78.53,24.73-81.45-2.65-5.82-2.25-12.25-6-18,38-38.8,74.22-79.62,115.25-115.3,2.92,1.12,3,6.72,4.35,9.1,2,3.52,6.44,5.68,6.35,10.2,5.86-.79,11.54-1.62,17.33-2.92a94,94,0,0,1-.17,19.72c-1.72.43-8.07-.65-8.82-.17a6.93,6.93,0,0,0,.19,3.68,7.3,7.3,0,0,1-3.55,3.39c-2.33,2-5,2.26-7,4.3-.88.88-2.94,4.7-4.49,6.36a49.89,49.89,0,0,1-6.07,5.64c-6.3,5-12.13,3.77-19.06,6.52-8.12,3.23-11.84,14.6-18.75,20.38-7.17,6,43,27.83,63.66,21.8-.5,24.2-2.94,48.29-3.54,72.49-.18,7.36-.49,14.72-.87,22.07-.22,4.32,1.22,15.31-1.07,18.45-2.07,2.84-10.66,5.39-13.65,6.71a178.22,178.22,0,0,1-127.86,5.65C213.65,369.27,204,360.3,202.36,355.07Z" />
    <path style={{fill: '#22292b'}}
          d="M432.38,135.09C459.77,197.8,448.29,272,403,323.21c-4.51-2.06-5.28-22.71-6.14-27.34-2.42-13.1-4.93-26.18-7.32-39.29-4.65-25.53-9.18-51.08-13.78-76.62,3-.38,2.71-33.38,3-37.69.38-6.29,2.29-14,1.39-20.18-1.16-8-8.51-6.8-15.56-10.23,6.32-4.52,16.19-13.23,23.4-16.24C387.66,107.66,418.61,131.18,432.38,135.09Z" />
    <path style={{fill: '#384247'}}
          d="M375.84,180q6.9,38.31,13.79,76.62c2.21,12.36,5.25,24.76,7.56,37.11,1,5.14,1,26.94,5.79,29.53-13.59,15.37-32.37,30.09-50.36,39.95.8-40.57,10.46-180.15,11.08-191,.74-13.05-2-38.19,1.48-50.85C368.87,137.4,372.94,163.75,375.84,180Z" />
    <path className="cls-8"
          d="M202.55,354.69c3.45,5.31,12.05,12.58,11.12,19.09a178.32,178.32,0,0,1-68-42.08c6.4-7.2,15.21-21,24.34-24.35C180.62,323.31,192.11,338.65,202.55,354.69Z" />
    <path className="cls-5"
          d="M167,309a109.22,109.22,0,0,1-21.12,22.86,179.76,179.76,0,0,1-16.75-19.18c3.37-2.79,9.34-7.15,11.79-10.55,3.71-5.13.55-6.79-3-11.36a98.18,98.18,0,0,0-9.39-11c-4.83-4.63-9.53-5.68-15.79-7.89.53-5-4-26.63,4-23.36,2.61,1.07,6.34,7.31,8.52,9.56,3.89,4,7.88,7.92,11.8,11.9C148.78,281.91,159.37,293.87,167,309Z" />
    <path className="cls-8"
          d="M321.14,89.45c7.17-9,15-17.35,22.8-25.77,3-3.21,10.09-9.27,9-14.19,2.76,1.47,5.85,3.38,8.53,5-6.9,8.28-5.34,11.43-3.64,21.89,1.95,12,4.4,24.39,6.75,36.34C349.64,107,334.73,97.58,321.14,89.45Z" />
    <path style={{fill: '#2a3133'}}
          d="M432.38,135.09c-12.89.33-28.64-8.44-40.65-12.62-8-2.78-9.23-4.24-8.09-13.22.77-6,.63-9.64,4.81-13.9,3.33-3.39,8.43-6.07,12.46-8.53A194.41,194.41,0,0,1,432.38,135.09Z" />
    <path className="cls-5"
          d="M363.84,111.85c-2.22-9.51-3.43-19.16-5-28.76-1.65-10.37-7.33-21,2.56-28.61,3.48,2.1,12.49,7.83,15.23,10.28s-.31,11.06-.7,14.75c-.88,8.39-1.91,16.4-.82,24.85C372.6,106.17,366.9,111.85,363.84,111.85Z" />
    <path style={{fill: '#3e484e'}}
          d="M375,104.35c-1.32-13.22-.38-26.49,1.62-39.6a178.06,178.06,0,0,1,24.25,22C392.06,92.37,384.1,99.14,375,104.35Z" />
    <path style={{fill: '#252c2e'}}
          d="M321.58,89.36c13.41,7.4,27.57,17.7,42.22,22.51,0,.55,0,.95,0,1.71-9.7,3.23-21,20.21-28,27.36s-14.68,14.26-21.9,21.52c-18.12,18.23-35.8,36.88-54.35,54.67-8.78,8.42-16.41,17.84-25,26.47-10.66,10.71-21.94,20.79-32.65,31.48Q191.95,285,182,294.94c-4,4-9.59,13.1-15.53,14.22a173.27,173.27,0,0,0-25.91-34.61c-8.68-9.19-20.93-18.45-27.77-29.1-3.94-6.13-3.63-14.28-4.33-21.31-1-10.29-1.95-20.59-3.16-30.86-.81-6.87-2.37-17.22-2.39-24.2,0-8.74,5.67-8.47,11.78-14.43,18.37-17.89,37.2-35.41,56.17-52.67,3.5-3.18,1.16-15-3.34-15.25-7.81-.44-15.82-1.56-22.66-5.64A177.84,177.84,0,0,1,185.2,50.83c.56,2.13,3.29,1.63,4.93,3.26,4.23,4.17,17.48,34.3,22.37,32.47,4-1.49,10.06-13.49,12.89-16.86,5.89-7,11.93-13.73,16.85-21.51C268.93,61.15,295.62,75,321.58,89.36Z" />
    <path style={{fill: '#354044'}}
          d="M145.16,81.15c6.17,3.47,12.76,2.95,19.56,3.61,3.17.31,18-.32,20,1.56,4,3.78-15.75,18.07-20,21.94C144,127.14,124.16,147,103.56,166c-1.17-1.8-.75-7.65-3.52-7.89A179.6,179.6,0,0,1,145.16,81.15Z" />
    <path className="cls-4"
          d="M242.54,48.25C238,57.67,229.46,65.93,223,74.05c-1.86,2.34-7.63,12.49-10.46,13.06-3.07.62-3.48-2-5.28-4.5-4.64-6.53-21.83-29-22.22-31.69,9.19-5,20.47-11,30.8-13.09,6.5-1.33,8.55.5,14.59,3.5C234.78,43.51,238.77,45.11,242.54,48.25Z" />
    <path style={{fill: '#49565f'}}
          d="M240.25,46.62c-6.17-2.55-19.49-5.16-25.19-8.62.86-.25,7.65-2.26,8.51-2.49a176.15,176.15,0,0,1,26.12-5.06C247.9,36.45,243.26,41.25,240.25,46.62Z" />
    <path className="cls-4"
          d="M112.74,271.89c9,3.17,13.46,3.71,19.6,11.26,5.72,7,16,14.88,6,21.91.69-5.29-8.89-16.73-12.52-19.55C118.51,279.84,114.83,282.33,112.74,271.89Z" />
    <path className="cls-14"
          d="M169.94,307.25c16.49-17.36,33.16-36.53,51.7-51.7,3.81,5.81,3.36,12,6,18,2.44,5.51,8.57,9.89,14,12.27,8.49,3.73,20.89-1.25,13.68,10.81-5.82,9.74-8.91,22.2-16.27,30.8a32.87,32.87,0,0,1-11.32,8.88c-3.82,1.71-12.79,1.43-14.29,6.13-12.91-1-5.31,10.39-10.59,12.83C192.06,339.15,180.27,323.7,169.94,307.25Z" />
    <path className="cls-14"
          d="M363.84,176.27c-.4,24.54-1.75,48.8-3.23,73.29-19.91,2.91-39.54,7.42-59.45,10.41-4.26.64-14.48,3.3-18.43,1.52S279.16,256,278.3,252c-1.3-6.15-2.29-8.6,2.09-13.88,3.32-4,8.19-6.87,12.2-10.09,3.32-2.66,6.9-5.36,9.56-8.71s3.65-7.77,6.84-10.56c6.07-5.32,16-3.94,22.93-8.63,4.75-3.23,8.08-7.58,11.92-11.64,2-2.14,3.4-2.51,6.48-4.6a16.27,16.27,0,0,0,4-3.23c1-1.23-.46-4,.57-4.77C355.36,175.59,363,176.46,363.84,176.27Z" />
    <path className="cls-14"
          d="M336.14,140.35c4.09-4.14,8.17-10.09,12.8-15.3s9.7-9.56,14.83-12.12c2.28,12.41,2.4,23.3,1.57,35.85-.43,6.48.35,8.74-6.6,10-3.9.73-8.14.43-12.1,1,.44-4.57-4.16-6.81-6.18-10.34C339.11,147.15,339,141.46,336.14,140.35Z" />
    <path style={{fill: '#232426'}}
          d="M366.78,131.59c-1-6.3-2.94-13.22-2.94-19.63,3.1-1.55,15.11,4.3,16.57,7.66,2,4.49-.62,15.27-1.06,20.6-.34,4.1,1.15,38.5-3.4,40.31C373,164.18,369.47,148,366.78,131.59Z" />
  </LogoCirclePattern>
}
const LogoLight = ({...props }) => {

  const { scrollYProgress } = useViewportScroll()

  return           <motion.path id="Light"  style={{fill: '#fff',
    stroke: "var(--textLink)",
    strokeWidth: "7px", pathLength: scrollYProgress}}
                                d="M270.34,26.15c74.46,0,143,47.67,168.74,117.59,26,70.73,4.14,152.22-53.74,200.43S242.86,399.1,178.17,360.36c-64.45-38.6-98.68-115.18-84.31-189C110.09,88,185.47,26.15,270.34,26.15Z" />
}

// class Logo extends React.Component {
const Logo = ({ animating, setAnimateLogo, ...props }) => {
  console.log('re render')



  return (<LogoMain {...props} viewBox="0 0 465 395" onClick={() => {
    console.log('hey')
    setAnimateLogo(!animating) }}>
    <defs>
      <radialGradient id="radial-gradient" cx="400.82" cy="61.86" r="88.69"
                      gradientTransform="matrix(1, 0, -0.05, 1, 14.17, 284)" gradientUnits="userSpaceOnUse">
        <stop offset="0.36" stopColor="#d8435b" stopOpacity="0" />
        <stop offset="1" stopColor="#882435" stopOpacity="0.9" />
      </radialGradient>
      <linearGradient id="linear-gradient" x1="271.5" y1="-226.4" x2="158.65" y2="83.94"
                      gradientTransform="matrix(1, 0, -0.02, 1, -0.15, 284.03)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#6a1424" />
        <stop offset="0.08" stopColor="#6c1525" stopOpacity="0.78" />
        <stop offset="0.13" stopColor="#721929" stopOpacity="0.63" />
        <stop offset="0.18" stopColor="#7d1e2f" stopOpacity="0.48" />
        <stop offset="0.22" stopColor="#8c2537" stopOpacity="0.36" />
        <stop offset="0.27" stopColor="#a02e41" stopOpacity="0.23" />
        <stop offset="0.31" stopColor="#b9384d" stopOpacity="0.12" />
        <stop offset="0.34" stopColor="#d6435a" stopOpacity="0.01" />
        <stop offset="0.35" stopColor="#d8435b" stopOpacity="0" />
        <stop offset="0.68" stopColor="#6a1424" />
        <stop offset="1" stopColor="#d8435b" stopOpacity="0" />
      </linearGradient>
      <radialGradient id="radial-gradient-2" cx="211.64" cy="175.52" fy="188.87176532526757" r="56.72"
                      gradientTransform="matrix(-0.64, 0.77, -2.06, -1.73, 709.89, 316.91)"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0.04" stopColor="#d8435b" />
        <stop offset="0.12" stopColor="#d8435b" />
        <stop offset="0.12" stopColor="#c63d53" stopOpacity="0.94" />
        <stop offset="0.12" stopColor="#9d3142" stopOpacity="0.79" />
        <stop offset="0.13" stopColor="#772532" stopOpacity="0.66" />
        <stop offset="0.13" stopColor="#571b24" stopOpacity="0.55" />
        <stop offset="0.13" stopColor="#3b1219" stopOpacity="0.45" />
        <stop offset="0.13" stopColor="#250c10" stopOpacity="0.37" />
        <stop offset="0.13" stopColor="#140609" stopOpacity="0.31" />
        <stop offset="0.13" stopColor="#090304" stopOpacity="0.27" />
        <stop offset="0.14" stopColor="#020101" stopOpacity="0.25" />
        <stop offset="0.14" stopOpacity="0.24" />
        <stop offset="0.71" stopOpacity="0.1" />
        <stop offset="1" stopOpacity="0.25" />
      </radialGradient>
      <radialGradient id="radial-gradient-3" cx="309.63" cy="-36.03" r="204.78" gradientTransform="translate(0 284)"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#ed3244" stopOpacity="0.1" />
        <stop offset="0.96" stopColor="#ed3244" stopOpacity="0.96" />
        <stop offset="1" stopColor="#ed3244" />
      </radialGradient>
      <linearGradient id="linear-gradient-2" x1="278.3" y1="-380.07" x2="345.42" y2="-82.21"
                      gradientTransform="translate(0 284)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#d8435b" stopOpacity="0" />
        <stop offset="0.01" stopColor="#d54259" stopOpacity="0.01" />
        <stop offset="0.05" stopColor="#be3a4f" stopOpacity="0.12" />
        <stop offset="0.1" stopColor="#aa3246" stopOpacity="0.24" />
        <stop offset="0.16" stopColor="#9b2c3f" stopOpacity="0.36" />
        <stop offset="0.22" stopColor="#90273a" stopOpacity="0.5" />
        <stop offset="0.29" stopColor="#dc2e3d" stopOpacity="0.66" />
        <stop offset="0.39" stopColor="#e33040" stopOpacity="0.9" />
        <stop offset="0.42" stopColor="#e33040" stopOpacity="0.86" />
        <stop offset="0.69" stopColor="#b5374b" stopOpacity="0.46" />
        <stop offset="0.89" stopColor="#ce4057" stopOpacity="0.16" />
        <stop offset="1" stopColor="#d8435b" stopOpacity="0" />
      </linearGradient>
    </defs>
    <g>
      <g>
        <g id="Circle">
          <LogoLight></LogoLight>
          <path
            d="M271.34,29.26c73.41.41,140.6,47.87,165.48,117,25.23,70.05,2.59,150.08-55,197-57.2,46.64-139.72,52.59-202.92,14.36S82.18,243.45,97,170.91C113.65,89.37,188.1,28.8,271.34,29.26Z" />
          <LogoCirclePatternElement animating={animating}></LogoCirclePatternElement>
        </g>
        <g>
          <path style={{fill: '#fff'}}
                d="M237.84,51.05c19.7,1.2,39.3,2.3,59,3.5,10.09,27.9,40.46,111.85,50.56,139.75C357,220.8,387,303.58,396.59,330.07a160.49,160.49,0,0,1-53.78,37.49l-11.22-32.25-12-34.26H211.34l-8.9,30.9-9.59,33.27a176.05,176.05,0,0,1-53.94-40.65c2.15-5.95,25.11-69.48,50.23-138.95C213.48,118.33,235.82,56.63,237.84,51.05Zm61.8,194-33.9-99.8-34.4,99.8Z" />
          <path className="cls-16"
                d="M239.84,54.25l61.5,3.5L414,368.05h-67.3l-23.4-67H215.14l-22.9,67h-65.5Zm63.5,190.8-33.9-99.8-34.3,99.8Z" />
        </g>
        <g id="S_layer">
          <g>
            <polygon style={{fill: 'url(#radial-gradient)'}}
                     points="393.04 317.65 413.04 368.05 346.14 368.05 307.24 249.95 361.24 227.95 393.04 317.65" />
            <polygon style={{fill: 'url(#linear-gradient)'}}
                     points="192.74 368.05 127.64 368.05 249.04 32.55 264.44 82.95 262.84 100.95 272.34 136.55 192.74 368.05" />
          </g>
          <path className="cls-16"
                d="M361.44,222.05c-5-18.4-13.4-36.45-20.72-54.06-6.08-14.63-12.11-29.27-17.23-44.27A515.45,515.45,0,0,1,306.18,59.4c-3.08-15.23-9.92-51.18,13-55.54C298.42-.49,272.32-3.8,253.86,9.23c-18.16,12.83-16.94,37.14-12.68,56.54,10.44,47.51,41.19,87.52,58.28,132.53,3.91,10.29,9.26,23.18,7.94,34.46-1.17,10-12.39,10.41-20.26,8.59-24.55-5.66-48.33-16.11-71.76-25.12-35.61-13.7-70.94-29.35-109-35.06-22.9-3.44-46.79-1.78-67.1,10.22C21.82,201.71,8.77,218.18,0,236.27c49.64-59.19,132.08-22.13,176.47,23.83,12.34,12.78,23.78,26.43,36.83,38.5,11.19,10.36,24.08,18.7,37.49,25.9,31.34,16.81,69,13.71,91.71-15.87,11-14.3,16.41-32.35,19.29-49.9C363.87,246,364.83,234.58,361.44,222.05Z" />
          <path className="cls-19"
                d="M246.11,82.65c.25.43.51.85.74,1.29,1.92,3.83,3.76,7.7,5.77,11.48,3.64,6.86,7.35,13.68,11.07,20.49,2.65,4.85,5.31,9.69,8.07,14.47,3.28,5.7,6.65,11.35,10,17q4.82,8.06,9.75,16.06,3.76,6.1,7.64,12.12c5.31,8.17,10.88,16.2,16,24.51,3.18,5.21,5.69,10.82,8.47,16.28,2.21,4.34,4.53,8.64,6.52,13.09q4.55,10.17,8.67,20.52a107,107,0,0,1,3.9,11.9,99.62,99.62,0,0,1,2.09,10.82,42.92,42.92,0,0,1,.48,7.22,24.05,24.05,0,0,1-2,9.84,11.41,11.41,0,0,1-4.7,5.26,6.37,6.37,0,0,1-2.56.72,95.12,95.12,0,0,1-11,.67,73.59,73.59,0,0,1-12.05-1.44,104.42,104.42,0,0,1-13-3.56c-5.21-1.79-10.36-3.79-15.39-6-6.49-2.87-12.93-5.88-19.25-9.13s-12.74-6.93-19.06-10.5-12.5-7.17-18.73-10.79c-6-3.48-11.95-7-17.94-10.51-5-2.91-10-5.8-15.1-8.62-5.45-3-10.88-6.09-16.43-8.92-6.92-3.53-13.92-6.89-20.93-10.23a177,177,0,0,0-25.75-9.73c-7.9-2.34-15.82-4.61-23.81-6.61-5.61-1.41-11.33-2.38-17-3.46-.53-.1-6.63-1.19-11.48-1.9a226.1,226.1,0,0,0-31.71-2.16c7.09-6.55,13.65-8.74,17.54-9.59,4.74-1,9.33-.53,18.52-.45,4.23,0,8.45.55,12.67.91q4.9.42,9.78,1,3.4.39,6.78,1c3.79.67,7.58,1.32,11.34,2.15,7.11,1.57,14.23,3.13,21.28,4.93,5,1.28,10,2.79,14.86,4.48,7.36,2.54,14.63,5.34,22,8q14.28,5.19,28.57,10.33,14.61,5.27,29.23,10.52c9.3,3.34,18.62,6.65,27.92,10,6.2,2.23,12.36,4.55,18.57,6.73a18.25,18.25,0,0,0,4.39.86,36.16,36.16,0,0,0,6.7.24c2.72-.26,5.48-.67,7.77-2.49a6.42,6.42,0,0,0,2.62-5.37A83.72,83.72,0,0,0,306,220.91a137.4,137.4,0,0,0-6.43-22.6c-2.18-5.73-4.47-11.44-7-17-2.92-6.43-6-12.77-9.28-19-3.67-7.1-7.57-14.08-11.36-21.11q-2.24-4.16-4.41-8.36c-2.29-4.41-4.64-8.79-6.84-13.25a206.6,206.6,0,0,1-10.94-25.14C248,89.51,246.84,85.41,246.11,82.65Z" />
          <g id="S">
            <path className="cls-20"
                  d="M0,236.27c8.65-18.86,22.28-35.68,40.64-45.8,21.6-11.9,47-12.78,70.83-8.62,23.14,4,45.17,12.38,67.18,20.34,19.11,6.91,38.07,14.21,57,21.69,5.63,2.23,11.24,4.5,16.9,6.63,12.18,4.59,26.61,10.93,39.67,11.84,14.26,1,16.87-7.72,14.94-20.11-2-13-7.16-26.08-12.16-38.26-12.49-30.43-32.91-56.8-44.12-87.88-7.71-21.39-18.52-50.9-8-73.18,13-27.53,50.94-23.86,75.43-18.87-6,2.89-10.2,5.07-12.9,11.6-2.8,6.78-2.77,14.8-2.44,22,.62,13.82,3.94,27.55,7.32,40.91,7.16,28.32,17,55.86,27.68,83,8.63,22,18.16,43.33,24.63,66.09,3.33,11.71.74,24.85-1.73,36.64-2.56,12.25-6.06,23.59-12.15,34.58-10.78,19.46-28.8,33.18-51.21,35.89-24.91,3-48.24-9.52-68-23.28-18.29-12.71-32.32-30.09-47.78-45.91-17.81-18.23-36.65-34.85-59.89-45.83-19.32-9.12-42.46-16.36-63.93-14.52C35.17,207.19,14.61,219.19,0,236.27Z" />
            <path id="detail1" className="cls-21"
                  d="M324.94,126.75c.31.8,3.57,7.79,2.57,8.49-1.5,1.05-22.07-45.83-23.3-49.05-7.78-20.42-22-54.45-4.51-73.65a27.48,27.48,0,0,1,19.74-8.69C304.16,6.51,303,24.4,304,36.74A317.28,317.28,0,0,0,324.94,126.75Z" />
          </g>
        </g>
        <path className="cls-5" d="M141.06,303" />
      </g>
    </g>
  </LogoMain>)
}

export default Logo



import React from "react"

const Footer = ({ author }) => {
  return (
    <footer className="mt-auto mb-12 text-center">
      © {new Date().getFullYear()}, {author}.
    </footer>
  )
}

export default Footer

import React from "react"

const defaultState = {
  visible: true,
  content: "testing",
  show: (content) => {
  },
  close: () => {
  },
  toggleVisible: () => {
  },
  setContent: (content) => {
  }
}

const ModalContext = React.createContext(defaultState)

class ModalProvider extends React.Component {
  state = {
    visible: false,
    content: "",
    modalClass: ""
  }

  show = (content, modalClass) => {
    let visible =  true
    let modal_content =  content
    console.log('arrived', modalClass)
    localStorage.setItem("visible", JSON.stringify(visible))
    this.setState({ visible: visible, content: modal_content, modalClass: modalClass })
  }

  close = () => {
    console.log("needs to be closed")
    let visible = false
    let modal_content = this.state.content
    localStorage.setItem("visible", JSON.stringify(visible))
    this.setState({ visible: visible, content: modal_content, modalClass: ""})
  }

  toggleVisible = () => {
    let visible = !this.state.visible
    localStorage.setItem("visible", JSON.stringify(visible))
    this.setState({ visible: visible })
  }

  setContent = (content) => {
    let modal_content =  content
    let visible = this.state.visible
    localStorage.setItem("content", JSON.stringify(modal_content))
    this.setState({ visible: visible, content: modal_content })
  }

  componentDidMount() {
    // Getting dark mode value from localStorage!
    // const visible = JSON.parse(localStorage.getItem("visible"))
    // const content = JSON.parse(localStorage.getItem("content"))
    //
    // this.setState({ visible:visible, content:content  })
  }

  render() {
    const { children } = this.props
    const { visible, content } = this.state
    return (
      <ModalContext.Provider
        value={{
          visible,
          content,
          toggleVisible: this.toggleVisible,
          show: this.show,
          close: this.close,
          setContent: this.setContent,
          modalClass: this.state.modalClass
        }}
      >
        {children}
      </ModalContext.Provider>
    )
  }
}

export default ModalContext

export { ModalProvider }

import React, { forwardRef, LegacyRef } from "react"
import classNames from "classnames"
import styled from '@emotion/styled'

export interface Props {
  className?: string;
  children?: React.ReactNode;
}

const AsideContent = styled.div`
      flex-grow: 1;
`;

const SidebarContent: React.ForwardRefRenderFunction<unknown, Props> = (
  { children, className, ...rest },
  ref
) => {
  const sidebarContentRef: LegacyRef<HTMLDivElement> =
    (ref as any) || React.createRef<HTMLDivElement>()
  return (
    <AsideContent {...rest} ref={sidebarContentRef} className={classNames("aside-content", className)}>
      {children}
    </AsideContent>
  )
}

export default forwardRef<unknown, Props>(SidebarContent)

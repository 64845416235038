// src/components/ThemeProvider.jsx
/** @jsx jsx */
import { Global, css, ThemeProvider as StyledThemeProvider, jsx } from '@emotion/react'


import { bgTextColor, CUBIC_BEZIER_TRANSITION, getTheme } from "../utils/theme"
import PRISM_THEME_LIGHT from "../assets/styles/prism-theme-light"
import PRISM_THEME_DARK from "../assets/styles/prism-theme-dark"

const darkTheme = getTheme("dark")
const lightTheme = getTheme("light")


const ThemeProvider = ({ children }) => {
  return (
    <StyledThemeProvider theme={lightTheme}>
      <Global
        styles={css`

    :root {
    
        --color-white: #FFFEFC;
        --color-black: #1E1B18;
    
        --oc-gray-0: #f8f9fa;
        --oc-gray-0-rgb: 248, 249, 250;
        --oc-gray-1: #f1f3f5;
        --oc-gray-1-rgb: 241, 243, 245;
        --oc-gray-2: #e9ecef;
        --oc-gray-2-rgb: 233, 236, 239;
        --oc-gray-3: #dee2e6;
        --oc-gray-3-rgb: 222, 226, 230;
        --oc-gray-7: #495057;
        --oc-gray-7-rgb: 73, 80, 87;
        --oc-gray-8: #343a40;
        --oc-gray-8-rgb: 52, 58, 64;
        --oc-gray-9: #212529;
        --oc-gray-9-rgb: 33, 37, 41;
        --oc-indigo-5: #5c7cfa;
        --oc-indigo-5-rgb: 92, 124, 250;
        --oc-indigo-6: #4c6ef5;
        --oc-indigo-6-rgb: 76, 110, 245;
        --oc-indigo-7: #4263eb;
        --oc-indigo-7-rgb: 66, 99, 235;
        --oc-grape-5: #cc5de8;
        --oc-grape-5-rgb: 204, 93, 232;
        --oc-grape-6: #be4bdb;
        --oc-grape-6-rgb: 190, 75, 219;
        --oc-grape-7: #ae3ec9;
        --oc-grape-7-rgb: 174, 62, 201;
        --oc-teal-5: #20c997;
        --oc-teal-5-rgb: 32, 201, 151;
        --oc-teal-6: #12b886;
        --oc-teal-6-rgb: 18, 184, 134;
        --oc-teal-7: #0ca678;
        --oc-teal-7-rgb: 12, 166, 120;
        --oc-pink-5: #f06595;
        --oc-pink-5-rgb: 240, 101, 149;
        --oc-pink-6: #e64980;
        --oc-pink-6-rgb: 230, 73, 128;
        --oc-pink-7: #d6336c;
        --oc-pink-7-rgb: 214, 51, 108;
        --oc-gray-5-rgb: 173, 181, 189;
    
        --oc-yellow-0: #fff9db;
        --oc-yellow-0-rgb: 255, 249, 219;
        --oc-yellow-1: #fff3bf;
        --oc-yellow-1-rgb: 255, 243, 191;
        --oc-yellow-2: #ffec99;
        --oc-yellow-2-rgb: 255, 236, 153;
        --oc-yellow-3: #ffe066;
        --oc-yellow-3-rgb: 255, 224, 102;
        --oc-yellow-4: #ffd43b;
        --oc-yellow-4-rgb: 255, 212, 59;
        --oc-yellow-5: #fcc419;
        --oc-yellow-5-rgb: 252, 196, 25;
        --oc-yellow-6: #fab005;
        --oc-yellow-6-rgb: 250, 176, 5;
        --oc-yellow-7: #f59f00;
        --oc-yellow-7-rgb: 245, 159, 0;
        --oc-yellow-8: #f08c00;
        --oc-yellow-8-rgb: 240, 140, 0;
        --oc-yellow-9: #e67700;
        --oc-yellow-9-rgb: 230, 119, 0;
    
        --color-dark--shade-1: var(--oc-gray-9);
        --color-dark--shade-2: var(--oc-gray-8);
        --color-dark--shade-3: var(--oc-gray-7);
    
        --color-light--shade-1: var(--oc-gray-0);
        --color-light--shade-2: var(--oc-gray-1);
        --color-light--shade-3: var(--oc-gray-2);
    
        --color-accent-1--shade-1: var(--oc-indigo-5);
        --color-accent-1--shade-2: var(--oc-indigo-6);
        --color-accent-1--shade-3: var(--oc-indigo-7);
    
        --color-accent-2--shade-1: var(--oc-grape-5);
        --color-accent-2--shade-2: var(--oc-grape-6);
        --color-accent-2--shade-3: var(--oc-grape-7);
    
        --color-positive--shade-1: var(--oc-teal-5);
        --color-positive--shade-2: var(--oc-teal-6);
        --color-positive--shade-3: var(--oc-teal-7);
    
        --color-negative--shade-1: var(--oc-pink-5);
        --color-negative--shade-2: var(--oc-pink-6);
        --color-negative--shade-3: var(--oc-pink-7);
    
        --color-highlight: var(--oc-yellow-3);
    
        --rgb-dark--shade-1: var(--oc-gray-9-rgb);
        --rgb-dark--shade-2: var(--oc-gray-8-rgb);
        --rgb-dark--shade-3: var(--oc-gray-7-rgb);
    
        --rgb-light--shade-1: var(--oc-gray-0-rgb);
        --rgb-light--shade-2: var(--oc-gray-1-rgb);
        --rgb-light--shade-3: var(--oc-gray-2-rgb);
    
        --rgb-accent-1--shade-1: var(--oc-indigo-5-rgb);
        --rgb-accent-1--shade-2: var(--oc-indigo-6-rgb);
        --rgb-accent-1--shade-3: var(--oc-indigo-7-rgb);
    
        --rgb-accent-2--shade-1: var(--oc-grape-5-rgb);
        --rgb-accent-2--shade-2: var(--oc-grape-6-rgb);
        --rgb-accent-2--shade-3: var(--oc-grape-7-rgb);
    
        --rgb-positive--shade-1: var(--oc-teal-5-rgb);
        --rgb-positive--shade-2: var(--oc-teal-6-rgb);
        --rgb-positive--shade-3: var(--oc-teal-7-rgb);
    
        --rgb-negative--shade-1: var(--oc-pink-5-rgb);
        --rgb-negative--shade-2: var(--oc-pink-6-rgb);
        --rgb-negative--shade-3: var(--oc-pink-7-rgb);
    
        --rgb-highlight: var(--oc-yellow-3-rgb);
    
        --color-overlay: rgba(var(--rgb-dark--shade-1), 0.7);
        --color-elevate: rgba(var(--oc-gray-5-rgb), 0.7);
        
        --max-width: 1200px;
    }

  

    html.dark {
        color-scheme: dark;
    }


      body{    
          background-color: var(--bg);
        /*font-family: Poppins, serif;*/
        font-weight: 300;
        // for rubber band effect in Chrome on MacOS and outside the scaled div with background color
        // add transition delay only after initial rendering for continuing reading while maintaining
        // scroll position in dark mode on refresh
 
        --bg: ${lightTheme.background};
        --lightBg: ${lightTheme.lightBackground};
        --textNormal: ${lightTheme.textNormal};
        --textTitle: ${lightTheme.textTitle};
        --textLink: ${lightTheme.textLink};
        --hr: ${lightTheme.hr};
        ${PRISM_THEME_LIGHT}
      } 
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      blockquote {
          transition: ${CUBIC_BEZIER_TRANSITION};
          color: var(--textTitle);
          
          font-weight: 500;
      }

      body.dark {
        -webkit-font-smoothing: antialiased;
        
        --bg: ${darkTheme.background};
        --lightBg: ${darkTheme.lightBackground};
        --textNormal: ${darkTheme.textNormal};
        --textTitle: ${darkTheme.textTitle};
        --textLink: ${darkTheme.textLink};
        --hr: ${darkTheme.hr};
        
        .container {
          background: ${darkTheme.background};
          color: ${darkTheme.color};
        }
        ${PRISM_THEME_DARK}
      }
      .animation {
          overflow: hidden;
      }

      a.gatsby-resp-image-link {
          color: var(--bg);
          transition: ease 200ms all;
      }
      
      a.gatsby-resp-image-link:hover {
          cursor: pointer;
          transform: translateY(-2px);
          box-shadow: 0 6px 13px 2px rgba(0, 0, 0, 0.25);
      }

      .footnotes {
          word-break: break-all;
      }
      
      header aside {
          font-style: italic;
      }
        
      button.light, button.light a {
          color: var(--oc-gray-1)
      }
      
      button a {
          box-shadow: unset;
      }
      
      button {
          border: none;
          margin: 0;
          padding: 0;
          width: auto;
          overflow: visible;
      
          background: transparent;
          cursor: pointer;
      
          /* inherit font & color from ancestor */
          color: inherit;
          font: inherit;
      
          /* Normalize \`line-height\`. Cannot be changed from \`normal\` in Firefox 4+. */
          line-height: normal;
      
          /* Corrects font smoothing for webkit */
          -webkit-font-smoothing: inherit;
          -moz-osx-font-smoothing: inherit;
      
          /* Corrects inability to style clickable \`input\` types in iOS */
          -webkit-appearance: none;
      
          outline: 0;
      }
  `}
      />


      <div
        css={css`
          ${bgTextColor}
          z-index: 1;
          position: relative
          // overflow: 'hidden',
        `}
      >
        {children}
      </div>
    </StyledThemeProvider>
  )
}


export { ThemeProvider }

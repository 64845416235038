import React, { useEffect, useState, useRef } from "react"
import { ThemeProvider } from "../context/ThemeContext"
import ModalContext, { ModalProvider } from "../context/ModalContext"

import { motion, AnimatePresence } from "framer-motion"

import Footer from "./footer"
import Header from "./header"

import Sidebar from "./sidebar.js"
import { graphql, useStaticQuery } from "gatsby"
import styled from '@emotion/styled'
import Modal from "./elements/modal"
import { EASE_IN_OUT_TRANSITION } from "../utils/theme"


const ModalContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  h1 {
    color: #5c3aff;
  }
  
`

// left: calc(50% + 150px);

// 1200px (--max-width) + 300px  /2
const MainWrapper = styled.div `
    // background-color: var(--bg);
    min-height: 100vh;
    .overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 100;
      display: none;
    }

  @media only screen and (max-width: 1024px) {
    .overlay {
      ${({ sidebarToggle }) => sidebarToggle && `
          display: block;
        `}
    }
  }
  
  @media only screen and (min-width: calc(1280px) ) {
    --center-margin: calc(50% + 150px);
  }


  ${({ collapsed }) => collapsed ? `
          --center-margin: calc(50% + 150px);
        `:`
          --center-margin: 50%;
        `}
  
`

const MainContent = styled.div`
    // position: absolute;
    top: 0;
    overflow: hidden;
    z-index: 10;
    padding: 0 20px 0;
    margin-left: 0;
    height: 100%;
    
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    
    & > * {
      flex-direction: row;
    }
    & > main {
      flex-grow: 1;
    }
    transition: transform ${EASE_IN_OUT_TRANSITION};
    @media only screen and (min-width: 1024px) {
          padding: 0 80px 0;
          width: calc(100% - 300px);
          transform: translateX(300px);
    }

    @media only screen and (min-width: 1024px) and (max-width: 1280px) {
      ${({ sidebarToggle }) => !sidebarToggle && `
            padding: 0 80px 0;
            height: 100vh;
            width: calc(100%);
            transform: translateX(0px);
      `}
    }
`


const MainContentSize = styled.div`
  max-width: var(--max-width);

  margin: 0 auto;
`

const duration = 0.25

const variants = {
  initial: (move) => {
    return {
      x: move.current === "right" ? "-20%" : move.current === "left" ? "20%" : "0%",
      y: move.current === "down" ? "-20%" : move.current === "up" ? "20%" : "0%",
      opacity: 0
    }
  },
  enter: (move) => {
    return {
      x: "0%",
      y: "0%",
      opacity: 1,
      transition: {
        duration: duration,
        delay: duration,
        when: "beforeChildren"
      }
    }
  },
  exit: (move) => {
    return {
      x: move.current === "right" ? "20%" : move.current === "left" ? "-20%" : "0%",
      y: move.current === "down" ? "20%" : move.current === "up" ? "-20%" : "0%",
      opacity: 0,
      transition: { duration: duration }
    }
  }
}

let first = true
const Layout = ({ location, title, children }) => {
  const [collapsed, setCollapsed] = useState(false)
  const lastPosition = useRef(location)
  const direction = useRef(1)
  const historyPosition = lastPosition.current
  lastPosition.current = location


  const width = 0

  const initial_state = width > 1024
  const [sidebarToggle, setSidebarToggled] = useState(initial_state)


  console.log("loc", location, historyPosition)

  direction.current = "right" // forward
  if (location.state !== null && location.state !== undefined && location.state["direction"] !== undefined) {
    direction.current = location.state["direction"]
  } else {
    direction.current = location.pathname.startsWith(historyPosition.pathname) ? "up" : "down"
  }
  console.log("action", direction.current)


  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)


  // Hacky solution for, not animate on 'first' page load.
  let isAnimate = first

  useEffect(() => {
    if (first) {
      first = false
      isAnimate = true
    }
  }, [first])

  return (
    <ThemeProvider>
      <ModalProvider>
        <MainWrapper sidebarToggle={sidebarToggle} className={collapsed  ? 'collapesed': ''}>

          <Sidebar
            header={data.site.siteMetadata.title}
            collapsed={collapsed}
            isActive={sidebarToggle}
            setToggled={setSidebarToggled}
          />
          {/*className={`${sidebarToggle ? "" : "overlay-toggled"}`}*/}
          <MainContent
            sidebarToggle={sidebarToggle}
          >
            <MainContentSize>
              <Header isActive={sidebarToggle} setToggled={setSidebarToggled} />
              <AnimatePresence
                initial={false}
                onExitComplete={() =>
                setTimeout(
                  () => document.body.classList.remove("animation"), 100 // dirty hack to close
                )
              }>
                <motion.main
                  key={location.pathname}
                  variants={variants}
                  initial={isAnimate ? false : "initial"}
                  animate="enter"
                  exit="exit"
                  custom={direction}
                  onAnimationStart={() => first ? void (0) : document.body.classList.add("animation")}

                >
                  {children}
                </motion.main>
              </AnimatePresence>


              <Footer author={data.site.siteMetadata.title} />
            </MainContentSize>
          </MainContent>

          <ModalContext.Consumer>
            {theme => (
              <Modal isOpen={theme.visible} modalClass={theme.modalClass} handleClose={() => {
                console.log(theme)
                theme.close()
              }}>
                <ModalContent >
                  {theme.content}
                </ModalContent>
              </Modal>
            )}
          </ModalContext.Consumer>
        </MainWrapper>
      </ModalProvider>
    </ThemeProvider>
  )
}

export default Layout

import React, { forwardRef, LegacyRef } from "react"
import classNames from "classnames"
import styled from '@emotion/styled'

export interface Props {
  className?: string;
  children?: React.ReactNode;
}

const AsideHeader = styled.div`
      position: relative;
      border-bottom: 1px solid rgba(173, 173, 173, 0.2);
      padding-top: 150px;
      font-size: 2.5rem;
      line-height: 3.5rem;
      margin-bottom: 10px;
      margin-top: 20px;
      font-family: Montserrat, sans-serif;
      text-align: center;
`;

const SidebarHeader: React.ForwardRefRenderFunction<unknown, Props> = (
  { children, className, ...rest },
  ref
) => {
  const sidebarHeaderRef: LegacyRef<HTMLDivElement> =
    (ref as any) || React.createRef<HTMLDivElement>()
  return (
    <AsideHeader {...rest} ref={sidebarHeaderRef} className={classNames("aside-header", className)}>
      {children}
    </AsideHeader>
  )
}

export default forwardRef<unknown, Props>(SidebarHeader)

import * as React from "react"
import { motion } from "framer-motion"
import styled from '@emotion/styled'
import tw from 'twin.macro'
import {EASE_IN_OUT_TRANSITION, lightBgColor} from '../../../utils/theme'

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
)

const SidebarButton = styled(lightBgColor)`
    ${tw`xl:invisible`}
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: fixed;
    top: 18px;
    right: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding-top: 10px;
    z-index: 100000;
`;


export const MenuToggle = ({ toggle }) => (
  <SidebarButton aria-label="Toggle Menu" onClick={toggle} as="button">
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" }
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 }
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        style={{ opacity: 1 }}
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" }
        }}
      />
    </svg>
  </SidebarButton>
)

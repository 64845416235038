import { useEffect, useLayoutEffect, useRef, useState } from "react"
import ResizeObserver from "resize-observer-polyfill"

export const useDimensions = ref => {
  const dimensions = useRef({ width: 1000, height: 1000 })

  useEffect(() => {
    let ro = undefined
    if (dimensions.current) {
      ro = new ResizeObserver((entries) => {
        entries.forEach(entry => {
          if (entry.target === ref.current) {
            //  dimensions.current = entry.cu
            // console.log("updated")
            dimensions.current.width = entry.contentRect.width
            dimensions.current.height = entry.contentRect.height
          }
        })
      })

      ro.observe(ref.current)
    }

    return () => {
      if (ro)
        ro.disconnect()
    }
  }, [ref])

  return dimensions.current
}

export function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])
  return size
}